import React from "react";
import "./heroArea.css";

function HeroArea() {
  return (
    <div className="heroArea">
      <LeftArea />
      <RightArea />
    </div>
  );
}

export default HeroArea;

function LeftArea() {
  return <div className="leftAreaRoot">Left Area</div>;
}

function RightArea() {
  return <div className="rightAreaRoot">Right Area</div>;
}
