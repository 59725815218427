import React from "react";
import "./header.css";
import logo from "./bhs.png";

function Header() {
  return (
    <div className="header">
      <Logo />
      <MainMenuArea />
      <LoginButton />
    </div>
  );
}

export default Header;

function Logo() {
  return (
    <div className="logoRootContainer">
      <img className="logo" src={logo} alt="Black Hat Logo" />
      <div className="websiteName">Black Hat Studio</div>
    </div>
  );
}

function MainMenuArea() {
  const menuItems = ["Home", "Art", "Contact", "About"];
  return (
    <div className="MainMenuRootContainer">
      {menuItems.map((menutItem, index) => (
        <div key={index} className="singleMenuItem">
          {menutItem}
        </div>
      ))}
    </div>
  );
}

function LoginButton() {
  return (
    <div className="loginButtonRootContainer">
      <div className="loginButton">Login</div>
    </div>
  );
}
